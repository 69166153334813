import React, { FC, memo } from 'react'
import { IParentFormDialogProps } from '../types'
import ParentFormDialogRequest from './ParentFormDialogRequest'

import { useParentFormDialog } from '../bll'

const ParentFormDialog: FC<IParentFormDialogProps> = (props) => {
  const { parentFormDialogRequest, parentFormDialogSuccess } = useParentFormDialog(props)

  return (
    <ParentFormDialogRequest
      open={parentFormDialogRequest.isActive}
      onClose={parentFormDialogRequest.closeDialog}
      messengersDialog={parentFormDialogSuccess}
    />
  )
}

export default memo(ParentFormDialog)
