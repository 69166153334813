import React, { FC, useMemo } from 'react'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import TelegramIcon from '@ucheba/ui/icons/icon__telegram-filled.svg'
import VkIcon from '@ucheba/ui/icons/icon__vk_square.svg'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import WhatsAppIcon from '@ucheba/ui/icons/icon__whatsapp-filled.svg'

import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/desktop'
import { Box } from '@ucheba/ui/components/Box/touch'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import content from '../data.json'
import { IRegDialog } from '../types'
import { useMessengersHref } from '../bll'

const RegDialog: FC<IRegDialog> = (props) => {
  const {
    open,
    onClose,
    params,
    whatsAppReg = true,
    queue = ['tg', 'wa', 'vk'],
    title,
    description,
  } = props

  const { vkHref, tgHref, waHref } = useMessengersHref(params)

  const TG = (
    <Button
      block
      color='orange'
      href={tgHref}
      StartIcon={<TelegramIcon width='28px' height='28px' />}
      onClick={() => {
        analyticEvent({
          targets: [
            EAnalyticEventTargets.ym,
            EAnalyticEventTargets.ur,
            EAnalyticEventTargets.gtm,
          ],
          eventName: EAnalyticEvents.requestToTG,
          section: EAnalyticSections.requestForm,
        })
      }}
      target='_blank'
    >
      {content.telegramButtonText}
    </Button>
  )

  const WA = (
    <Button
      block
      color='orange'
      StartIcon={<WhatsAppIcon width='28px' height='28px' />}
      href={waHref}
      onClick={() => {
        analyticEvent({
          targets: [
            EAnalyticEventTargets.ym,
            EAnalyticEventTargets.ur,
            EAnalyticEventTargets.gtm,
          ],
          eventName: EAnalyticEvents.requestToWhatsApp,
          section: EAnalyticSections.requestForm,
        })
      }}
      target='_blank'
    >
      {content.whatsAppButtonText}
    </Button>
  )

  const VK = (
    <Button
      block
      color='orange'
      href={vkHref}
      StartIcon={<VkIcon width='28px' height='28px' />}
      onClick={() => {
        analyticEvent({
          targets: [
            EAnalyticEventTargets.ym,
            EAnalyticEventTargets.ur,
            EAnalyticEventTargets.gtm,
          ],
          eventName: EAnalyticEvents.requestToVK,
          section: EAnalyticSections.requestForm,
        })
      }}
      target='_blank'
    >
      {content.vkButtonText}
    </Button>
  )

  const elementsQueue = useMemo(() => {
    return queue.map((el) => {
      switch (el) {
        case 'tg':
          return TG
        case 'wa':
          return whatsAppReg ? WA : null
        case 'vk':
          return VK
        default:
          return null
      }
    })
  }, [TG, VK, WA, queue, whatsAppReg])

  return (
    <BottomSheet open={open} toggleOpen={onClose}>
      <Box spacing='large'>
        {typeof title === 'string' ? (
          <>{title && <Heading h2={title || content.title} spacing='small' />}</>
        ) : (
          <Heading h2={title || content.title} spacing='small' />
        )}

        {typeof description === 'string' ? (
          <>
            {description && (
              <Text fontSize='large'>{description || content.requestText}</Text>
            )}
          </>
        ) : (
          <Text fontSize='large'>{description || content.requestText}</Text>
        )}

        <Spacing spacing='large' />

        {elementsQueue.map((el, index) => {
          return (
            <>
              {el}

              {index !== elementsQueue.length - 1 && <Spacing spacing='small' />}
            </>
          )
        })}
      </Box>
    </BottomSheet>
  )
}

export default RegDialog
