import React, { FC, memo, useMemo } from 'react'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import VkIcon from '@ucheba/ui/icons/icon__vk_square.svg'
import TgIcon from '@ucheba/ui/icons/icon__telegram-filled.svg'
import { Description } from '@ucheba/ui/components/Description/desktop'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/desktop'
import { Box } from '@ucheba/ui/components/Box/touch'
import styled from 'styled-components'
import { isBrowser } from '@ucheba/utils/helpers/core'
import { IEventNoticeNotAllowedMessage } from '../types'
import data from '../data.json'
import ImgTop from '../assets/img__notice_touch.png'

const Image = styled.img`
  width: 100%;
`

const BoxST = styled(Box)`
  border-radius: 8px 8px 0 0;
`

/** Нотис при успешной регистрации на мероприятие (не стартовавшее) */
const EventNoticeNotAllowedMessage: FC<IEventNoticeNotAllowedMessage> = (props) => {
  const { open, isHybrid, isOnline, onClose } = props

  const noticeData = useMemo(() => {
    if (isHybrid) {
      return data.hybrid
    }
    if (isOnline) {
      return data.online
    }
    return data.offline
  }, [isHybrid, isOnline])

  const domain = useMemo(() => {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (isBrowser) {
      if (window.location.host.includes('school')) {
        return process.env.NEXT_PUBLIC_URL as string
      }
    }

    return ''
  }, [isBrowser])

  return (
    <BottomSheet open={open} toggleOpen={onClose}>
      <BoxST color='navy'>
        <Image src={ImgTop} />

        <Spacing spacing='small' />

        <Heading h3={noticeData.title} />

        <Spacing spacing='xxsmall' />

        <Description description={noticeData.description} />

        <Spacing spacing='small' />

        {noticeData.buttons.map((button) => {
          return (
            <>
              <Button
                color='gold'
                target='_blank'
                key={button.id}
                href={domain + button.href}
                EndIcon={
                  button.id === 'vk' ? <VkIcon width='20px' /> : <TgIcon width='20px' />
                }
                block
                justifyContent='space-between'
                onClick={() => {
                  onClose()

                  // отправляем аналитику клика-перехода в яндекс-метрику
                  analyticEvent({
                    targets: [EAnalyticEventTargets.ym, EAnalyticEventTargets.ur],
                    eventName: EAnalyticEvents.vkNoticeClickLead,
                    section: EAnalyticSections.eventNoticeNotAllowedMessages,
                  })
                }}
              >
                {button.text}
              </Button>
              <Spacing spacing='small' />
            </>
          )
        })}
      </BoxST>
    </BottomSheet>
  )
}

export default memo(EventNoticeNotAllowedMessage)
