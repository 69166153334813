import React, { FC, memo } from 'react'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Form } from '@ucheba/ui/components/Form/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { Radio } from '@ucheba/ui/components/Radio/touch'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { TextField } from '@ucheba/ui/components/TextField/touch'
import { ErrorText } from '@ucheba/ui/components/ErrorText/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import { ErrorMessage, Field, Formik } from 'formik'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/touch'
import { Box } from '@ucheba/ui/components/Box/touch'
import { IParentFormDialogRequestProps } from '../types'
import { useParentFormDialogRequest } from '../bll'

const ParentFormDialogRequest: FC<IParentFormDialogRequestProps> = (props) => {
  const { onClose, open } = props

  const { initialValues, isStudent, isLoading, onSubmit, formText, isCorrectPhone } =
    useParentFormDialogRequest(props)

  return (
    <BottomSheet open={open} toggleOpen={onClose}>
      <Box>
        <Heading h2='Остался один шаг до получения подарка!' textAlign='center' />

        <Text>{formText}</Text>

        <Spacing spacing='xlarge' />

        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
          {({ values }) => {
            return (
              <Form>
                {isStudent && (
                  <>
                    <Grid spacing='small'>
                      <GridCol width='auto'>
                        <Field
                          as={Radio}
                          type='radio'
                          name={EDataKeys.isParent}
                          value='false'
                          label='Я старшеклассник'
                        />
                      </GridCol>

                      <GridCol width='auto'>
                        <Field
                          as={Radio}
                          type='radio'
                          name={EDataKeys.isParent}
                          value='true'
                          label='Я родитель'
                        />
                      </GridCol>
                    </Grid>

                    <Spacing spacing='xlarge' />
                  </>
                )}

                <div>
                  <Field
                    as={TextField}
                    name={EDataKeys.parentName}
                    label={
                      values[EDataKeys.isParent] === 'false'
                        ? 'Имя родителя'
                        : 'Имя ребёнка'
                    }
                    required
                  />

                  <ErrorMessage component={ErrorText} name={EDataKeys.parentName} />

                  <Spacing spacing='xlarge' />

                  <Field
                    as={TextField}
                    name={EDataKeys.parentPhone}
                    type='tel'
                    label={
                      values[EDataKeys.isParent] === 'false'
                        ? 'Телефон родителя'
                        : 'Телефон ребёнка'
                    }
                    required
                  />

                  {isCorrectPhone && <ErrorText>Некорректный телефон</ErrorText>}
                </div>

                <Spacing spacing='xlarge' />

                <Button
                  block
                  type='submit'
                  color='orange'
                  progress={isLoading}
                  disabled={
                    (!values[EDataKeys.parentName] || !values[EDataKeys.parentPhone]) &&
                    values[EDataKeys.isParent] === 'false'
                  }
                >
                  Готово
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </BottomSheet>
  )
}

export default memo(ParentFormDialogRequest)
